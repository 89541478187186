import React, { useState, useRef } from 'react'
import GruaContacto from '../imgs/grua-contacto.png'
import emailjs from 'emailjs-com';

function Contacto() {
    const [contactoForm, setFormContacto] = useState({
        user_name: '',
        user_email: '',
        message: ''
    })

    const form = useRef();

    const handleChange = e => {
        setFormContacto({
            ...contactoForm,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setFormContacto({
            user_name: '',
            user_email: '',
            message: ''
        })
        try {
            emailjs.sendForm(process.env.REACT_APP_SERVICEID, process.env.REACT_APP_TEMPLATEID, form.current, process.env.REACT_APP_USERID)
                .then((result) => {
                    alert("Tu mensaje se envió correctamente, nos pondremos en contacto.")
                }, (error) => {
                    alert("Tu mensaje no fue enviado por un error interno. Puedes contactarnos vía WhatsApp 55 3443 9138")
                });
        } catch (e) {
            alert("Tu mensaje no fue enviado por un error interno. Puedes contactarnos vía WhatsApp 55 3443 9138")
        }
    };

    return (
        <section className='row' id='contacto'>
            <div className='col-12' align="center" style={{ "marginTop": "50px", "marginBottom": "50px", "color": "#043071" }}>
                <h2>
                    <b>
                        CONTÁCTANOS
                    </b>
                </h2>
            </div>
            <div className='col-12 col-sm-12 col-md-6 d-flex align-items-center'>
                <form ref={form} onSubmit={handleSubmit}>
                    <div className="row ">
                        <div className="col-md-6 pt-4">
                            <input
                                type="text"
                                class="form-control form-control-lg"
                                placeholder="Nombre Completo"
                                name='user_name'
                                value={contactoForm.user_name}
                                onChange={handleChange}
                                minlength="5"
                                required />
                        </div>
                        <div className="col-md-6 pt-4">
                            <input
                                type="email"
                                class="form-control form-control-lg"
                                placeholder="Correo Electrónico"
                                name='user_email'
                                value={contactoForm.user_email}
                                onChange={handleChange}
                                minlength="10"
                                required />
                        </div>
                        <div className='col-md-12 pt-4'>
                            <textarea
                                class="form-control form-control-lg"
                                id="validationTextarea"
                                placeholder="Mensaje..."
                                name='message'
                                value={contactoForm.message}
                                onChange={handleChange}
                                minlength="10"
                                rows="5" cols="50"
                                required></textarea>
                        </div>
                        <div className='col-md-12 pt-4' align="right">
                            <button className='btn btn-primary btn-lg' type='submit'>Enviar</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className='col-12 col-sm-12 col-md-6'>
                <img src={GruaContacto}
                    alt="logo-header-img"
                    className='img-fluid' />
            </div>
        </section>
    )
}

export default Contacto