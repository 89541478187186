import React, { useState, useEffect } from 'react'
import Mano from '../imgs/nuestrovalor-icons/mano.png'
import Persona from '../imgs/nuestrovalor-icons/persona.png'
import Calendario from '../imgs/nuestrovalor-icons/calendario.png'
import Respeto from '../imgs/nuestrovalor-icons/respeto.png'
import Pieza from '../imgs/nuestrovalor-icons/pieza.png'
import Cliente from '../imgs/nuestrovalor-icons/cliente.png'
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Navigation, Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function NuestroValor() {
    const [windowWidth] = useState(window.innerWidth);
    const [slidesPer, setSlidesPer] = useState(3);

    useEffect(() => {
        if (windowWidth >= 750) {
            setSlidesPer(2)
        }else{
            setSlidesPer(1)
        }
    }, [windowWidth]);

    return (
        <section className='row' id='nuestro-valor'>
            <div className='col-12' align="center">
                <h2>
                    <b>
                        <span style={{ color: '#043071' }}>NUESTRO </span><span>VALOR</span>
                    </b>
                </h2>
            </div>
            <div className='col-12'>
                <Swiper
                    // install Swiper modules
                    modules={[Autoplay, Navigation, Pagination]}
                    slidesPerView={slidesPer}
                    spaceBetween={50}
                    centeredSlides={true}
                    autoplay={{
                        delay: 4500,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    }}
                    navigation
                    pagination={{ clickable: true }}
                >
                    <SwiperSlide>
                        <div className='card-valor' align="center">
                            <img src={Mano}
                                alt="logo-header-img-mano" />
                            <p>Valoramos la honestidad en todas nuestras interacciones con nuestros clientes y proveedores.</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='card-valor' align="center">
                            <img src={Persona}
                                alt="logo-header-img-persona" />
                            <p>Entendemos sus necesidades y preocupaciones, trabajando para superar sus expectativas. Ofreciendo un servicio excepcional para construir relaciones sólidas a largo plazo.</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='card-valor' align="center">
                            <img src={Calendario}
                                alt="logo-header-img-calendario" />
                            <p>Somos transparentes en cuanto a tiempos de entrega, costos y calidad de servicio.</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='card-valor' align="center">
                            <img src={Respeto}
                                alt="logo-header-img-respeto" />
                            <p>Tratamos a todos nuestros clientes, proveedores y empleados con respeto, valorando sus necesidades y preocupaciones. Construyendo relaciones sólidas para ofrecer un servicio de alta calidad.</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='card-valor' align="center">
                            <img src={Pieza}
                                alt="logo-header-img-pieza" />
                            <p>Nos aseguramos de estar bien informados y actualizados en los últimos avances y mejores prácticas en el campo de la logística. Esto nos permite ofrecer soluciones eficientes y costo-efectivas a nuestros clientes. Minimizando los riesgos, problemas en el proceso de transporte y almacenamiento de sus productos.</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='card-valor' align="center">
                            <img src={Cliente}
                                alt="logo-header-img-cliente" />
                            <p>Ofrecemos un servicio de atención al cliente disponible las 24 horas, los 7 días de la semana. Esto significa que nuestros clientes pueden contactarnos en cualquier momento, obtener respuestas rápidas y precisas.</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    )
}

export default NuestroValor