import React from 'react'
import Satisfaccion from '../imgs/mv/satistaccion.png'
import Mundo from '../imgs/mv/mundo.png'

function MisionVision() {
    return (
        <section className='row' id='mision-vision' align="center">
            <div className='col-12'>
                <h2>
                    <b>
                        <span style={{ color: '#043071' }}>MISIÓN Y </span><span>VISIÓN</span>
                    </b>
                </h2>
            </div>
            <div className='col-12 col-sm-12 col-md-6'>
                <img src={Satisfaccion}
                    alt="logo-mision-vision-satisfaccion" />
                <p>Satisfacer las necesidades de manera confiable con soluciones integrales. </p>
            </div>
            <div className='col-12 col-sm-12 col-md-6'>
                <img src={Mundo}
                    alt="logo-mision-vision-mundo" />
                <p>
                    Ser su socio para logística y transporte 
                    <span style={{color: "#0397CD"}}> local, regional y global.</span>
                </p>
            </div>
        </section>
    )
}

export default MisionVision