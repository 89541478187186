import React from 'react'
import ImgCoberturaAduanal from '../imgs/cobertura-aduanal.png'

function CoberturaAduanal() {
    return (
        <section className='row' id='cobertura-aduanal'>
            <div className='col-12' align="center">
                <h2>
                    <b>
                        <span>COBERTURA </span><span style={{ color: '#043071' }}>ADUANAL</span>
                    </b>
                </h2>
            </div>
            <div className='col-12' align="center">
                <img src={ImgCoberturaAduanal}
                    alt="logo-header-img"
                    className='img-fluid' />
            </div>
        </section>
    )
}

export default CoberturaAduanal