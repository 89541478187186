import React from 'react'
import Whatsapp from '../imgs/whatsapp.png'
import { isMobile } from 'react-device-detect';

function WhatsAppButton() {
    return (
        <div>
            {isMobile ?
                <a href="https://wa.me/+5215534439138"
                    className="whatsapp-btn">
                    <img src={Whatsapp} alt='Boton de WhatsApp' />
                </a>
                :

                <a href="https://web.whatsapp.com/send?phone=+5215534439138"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whatsapp-btn">
                    < img src={Whatsapp} alt='Boton de WhatsApp' />
                </a >
            }
        </div>

    );

}

export default WhatsAppButton