import React from 'react'
import LogoHeader from '../imgs/logo-header.png'
import { isMobile } from 'react-device-detect';

function Header() {
    return (
        <div className='row' id='header-kj'>

            {isMobile ?
                <video
                    autoplay="true" muted loop playsInline
                    className='header-video'>
                    <source src='https://kjlogistics.s3.us-west-1.amazonaws.com/header-Kj-Logistics-opt.mp4' type="video/mp4"></source>
                </video>
                :
                <video
                    autoplay="true"
                    className='header-video'
                    muted="true"
                    loop="true">
                    <source src='https://kjlogistics.s3.us-west-1.amazonaws.com/header-Kj-Logistics-opt.mp4' type="video/mp4"></source>
                </video>}
            <img src={LogoHeader} alt="logo-header-img" class="logo-header img-fluid" />
        </div>
    )
}

export default Header