import React, { useState, useEffect } from 'react'
import instagram from '../imgs/instagram.png'
import facebook from '../imgs/facebook.png'
import LogoKJ from '../imgs/logo.png'
import { Link, Events } from 'react-scroll';

function Navbar() {
    const [activeLink, setActiveLink] = useState('header-kj');

    const handleSetActive = (to) => {
        setActiveLink(to);
    };

    Events.scrollEvent.register('begin', (to, element) => {
        setActiveLink(to);
    });

    useEffect(() => {
        //Ocultar la navbar ya que el data-toggle de bootstrap no funciono
        const navbarLinks = document.querySelectorAll(".navbar-nav a");
        navbarLinks.forEach(link => {
            link.addEventListener("click", () => {
                document.querySelector(".navbar-collapse").classList.remove("show");
            });
        });
    }, [])

    function redirectToLoginPage() {
        const URL_DE_INICIO_DE_SESION = 'https://app.kj-logistics.com/';
        window.open(URL_DE_INICIO_DE_SESION, '_blank');
    }

    return (
        <div className='full-navbar'>
            <div id='top-navbar'>
                <span className='text-topnavbar'>
                    <p data-aos="fade-down">
                        <i class="bi bi-telephone-fill"></i> 55 3443 9138 o 442 170 8516
                    </p>
                    <p data-aos="fade-down">
                        <i class="bi bi-envelope-at-fill"></i> operaciones@kj-logistics.com
                    </p>
                    <p className='redes'>
                        <a href="https://www.instagram.com/kj_logistics/" target="_blank" rel="noreferrer">
                            <img src={instagram} alt="insta" height='15'
                                data-aos="fade-down" data-aos-delay="200" />
                        </a>

                        <a href="https://www.facebook.com/profile.php?id=100064372291202" target="_blank" rel="noreferrer">
                            <img src={facebook} alt="insta" height='15'
                                data-aos="fade-down" data-aos-delay="400" />
                        </a>
                    </p>
                    <p className='right-align' data-aos="fade-down" onClick={() => redirectToLoginPage()}>
                        <i class="bi bi-box-arrow-in-right"></i> Inicia sesión
                    </p>
                </span>
            </div>
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#inicio"
                        data-aos="zoom-in"
                        data-aos-duration="2000">
                        <img src={LogoKJ} alt="logo-kj" height='50' />
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"
                                data-aos="zoom-in"
                                data-aos-delay="100">
                                <Link
                                    activeClass={activeLink === 'header-kj' ? 'active' : ''}
                                    className="nav-link"
                                    to="header-kj"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    offset={-120}
                                    onSetActive={handleSetActive}
                                >
                                    Inicio
                                </Link>
                            </li>
                            <li class="nav-item"
                                data-toggle="collapse" data-target=".navbar-collapse.show"
                                data-aos="zoom-in"
                                data-aos-delay="200">
                                <Link
                                    activeClass={activeLink === 'quienes-somos' ? 'active' : ''}
                                    className="nav-link"
                                    to="quienes-somos"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    offset={-120}
                                    onSetActive={handleSetActive}
                                >
                                    ¿Quiénes somos?
                                </Link>
                            </li>
                            <li class="nav-item"
                                data-toggle="collapse" data-target=".navbar-collapse.show"
                                data-aos="zoom-in"
                                data-aos-delay="300">
                                <Link
                                    activeClass={activeLink === 'servicios' ? 'active' : ''}
                                    className="nav-link"
                                    to="servicios"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    offset={-120}
                                    onSetActive={handleSetActive}
                                >
                                    Nuestros Servicios
                                </Link>
                            </li>
                            <li class="nav-item"
                                data-toggle="collapse" data-target=".navbar-collapse.show"
                                data-aos="zoom-in"
                                data-aos-delay="400">
                                <Link
                                    activeClass={activeLink === 'cobertura-aduanal' ? 'active' : ''}
                                    className="nav-link"
                                    to="cobertura-aduanal"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    offset={-120}
                                    onSetActive={handleSetActive}
                                >
                                    Cobertura Aduanal
                                </Link>
                            </li>
                            <li class="nav-item"
                                data-toggle="collapse" data-target=".navbar-collapse.show"
                                data-aos="zoom-in"
                                data-aos-delay="500">
                                <Link
                                    activeClass={activeLink === 'nuestro-valor' ? 'active' : ''}
                                    className="nav-link"
                                    to="nuestro-valor"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    offset={-120}
                                    onSetActive={handleSetActive}
                                >
                                    Nuestro Valor
                                </Link>
                            </li>
                            <li class="nav-item"
                                data-toggle="collapse" data-target=".navbar-collapse.show"
                                data-aos="zoom-in"
                                data-aos-delay="600">
                                <Link
                                    activeClass={activeLink === 'contacto' ? 'active' : ''}
                                    className="nav-link"
                                    to="contacto"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    offset={-120}
                                    onSetActive={handleSetActive}
                                >
                                    Contacto
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className='navbar-spacer'></div>
        </div>
    )
}

export default Navbar