import React from 'react'

function Footer() {
    return (
        <section className='row' id='footer' align='center'>
            <div className='col-12' id='top-footer'>
                <div className='row' id='text-top'>
                    <div className='col-12 col-sm-12 col-md-6'>
                        <h2
                        data-aos="zoom-in"
                        data-aos-delay="200"><b>Gerente de Aduanas</b></h2>
                        <p
                        data-aos="zoom-in"
                        data-aos-delay="400">
                            Josimar Ramírez <br />
                            <i class="bi bi-telephone"></i> 55 3443 9138 <br />
                            <i class="bi bi-chat-left-dots"></i> josimar.ramirez@kj-logistics.com
                        </p>
                    </div>
                    <div className='col-12 col-sm-12 col-md-6'>
                        <h2
                        data-aos="zoom-in"
                        data-aos-delay="200"><b>Gerente de Logística</b></h2>
                        <p
                        data-aos="zoom-in"
                        data-aos-delay="400">
                            Claudia López<br />
                            <i class="bi bi-telephone"></i> 442 389 7588 <br />
                            <i class="bi bi-chat-left-dots"></i> claudia.lopez@kj-logistics.com
                        </p>
                    </div>
                    <div className='col-12 mt-4'
                    data-aos="zoom-in"
                    data-aos-delay="600">
                        <h3>
                            <p> <i class="bi bi-map"></i>
                                <br />Rio Lerma 110, Menchaca I, 76147 Querétaro
                            </p>
                        </h3>
                    </div>
                </div>
            </div>

            <div className='col-12' id='bot-footer'>
                <p className='text-footer'
                data-aos="zoom-in"
                data-aos-delay="200">
                    <span>® KJ Logistics. Todos los derechos reservados.</span><br />
                    <p  className='a-p'
                        data-toggle="modal"
                        data-target="#modalAvisoPrivacidad">Aviso de privacidad
                    </p>
                </p>
                <div class="modal fade" id="modalAvisoPrivacidad" tabindex="-1" aria-labelledby="modalAvisoPrivacidad" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalAvisoPrivacidad" style={{ color: "black" }}><b>AVISO DE PRIVACIDAD</b></h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" align="left" style={{ color: "black" }}>
                                <p>Nuestra empresa KJ Logistics respeta y protege la privacidad de los datos personales que recopilamos en nuestra landing page. Los datos que recopilamos a través del formulario de contacto son: nombre, correo electrónico y mensaje. Estos datos serán utilizados únicamente para responder a sus consultas y proporcionarle información sobre nuestros servicios. No compartimos ni vendemos sus datos a terceros.</p>
                                <p>Si desea acceder, actualizar o eliminar sus datos personales, por favor contáctenos a través de nuestro correo electrónico (operaciones@kj-logistics.com) o número telefónico proporcionados en la página.</p>
                                <p> Nos comprometemos a mantener la seguridad de sus datos personales y a cumplir con las leyes aplicables en materia de privacidad y protección de datos, incluyendo la Ley Federal de Protección de Datos Personales en Posesión de Particulares y su Reglamento, así como a los lineamientos del Instituto Nacional de Acceso a la Información y Protección de Datos (INAI).
                                    Si tiene alguna pregunta o inquietud sobre nuestro aviso de privacidad, por favor contáctanos.</p>
                                <p>KJ Logistics se reserva el derecho de actualizar y/o modificar periódicamente el presente aviso de privacidad para reflejar los cambios que se produzcan en nuestros servicios. Será responsabilidad del usuario revisar periódicamente el contenido del aviso de privacidad en nuestro sitio</p>
                                <p><a href="https://home.inai.org.mx/">INAI</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer
