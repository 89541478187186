import React from 'react'
import PoliciaLogo from '../imgs/logos-servicios/policia.png'
import CarreteraLogo from '../imgs/logos-servicios/carretera.png'
import CamionLogo from '../imgs/logos-servicios/camion.png'

function Servicios() {
  return (
    <section className='row justify-content-center' id='servicios' align="center">
      <div className='top-servicios col-12'>
        <h2>
          <b>
            <span style={{ color: 'black' }}>NUESTROS </span><span style={{ color: '#fff' }}>SERVICIOS</span>
          </b>
        </h2>
        <p>
          <b>
            Somos un equipo experto con conocimientos del sector, relaciones sólidas con navieras, aerolíneas, transportistas, agentes aduanales y una red global en 80 países.
            <span style={{ color: "black" }}> Somos su apoyo para lograr sus objetivos comerciales a través de soluciones efectivas.</span>
          </b>
        </p>
      </div>


      <div className='col-12 col-md-6 col-lg-4'>
        <div class="card">
          <img src={PoliciaLogo} class="card-img-top mx-auto d-block" alt="..." />
          <div class="card-body">
            <h5 class="card-title"><b>DESPACHO ADUANAL</b></h5>
            <p class="card-text">Contamos con una extensa cobertura en las aduanas más importantes, con una patente titular y una de respaldo; con más de 15 años de experiencia, asegurando el mejor trato a su mercancía y ofreciendo soluciones efectivas.</p>
            <button class="btn btn-primary"
              data-toggle="modal" data-target="#modalServicioAduanal">Ver más</button>
          </div>
        </div>
        <div class="modal fade" id="modalServicioAduanal" tabindex="-1" aria-labelledby="modalServicioAduanal" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modalServicioAduanal"><b>DESPACHO ADUANAL</b></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" align="left">
                <ul>
                  <b>
                    <li>Previo en Origen.</li>
                    <li>Clasificación arancelaria de mercancías.</li>
                    <li>Asesoría en Comercio Internacional y Derecho Aduanero.</li>
                    <li>Administración de Tratados y Acuerdos de Libre Comercio.</li>
                    <li>Despacho Aduanal.</li>
                  </b>
                  <ul className='sub-points'>
                    <li>Mercancía General.</li>
                    <li>Mercancía Sensible.</li>
                    <ul>
                      <li>Farma, textil, calzado, siderúrgico, químicos, operaciones vulnerables, etc.</li>
                    </ul>
                  </ul>
                  <b>
                    <li>Operaciones Virtuales.</li>
                    <li>Gestión de permisos. </li>
                    <li>Seguro de mercancías</li>
                  </b>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='col-12 col-md-6 col-lg-4'>
        <div class="card">
          <img src={CarreteraLogo} class="card-img-top mx-auto d-block" alt="..." />
          <div class="card-body">
            <h5 class="card-title"><b>LOGÍSTICA</b></h5>
            <p class="card-text">
              Le ofrecemos servicio integral Door to Door, contamos con socios logísticos, convenios con líneas navieras y aerolíneas que hacen posible este servicio en tiempo ofreciendo costos competitivos.
            </p>
            <button class="btn btn-primary"
              data-toggle="modal" data-target="#modalServicioDeLogistica">Ver más</button>
          </div>
        </div>
        <div class="modal fade" id="modalServicioDeLogistica" tabindex="-1" aria-labelledby="modalServicioDeLogistica" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modalServicioDeLogistica"><b>LOGÍSTICA</b></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" align="left">
                <ul>
                  <b><li>Transporte Aéreo</li></b>
                  <ul className='sub-points'>
                    <li>Servicio regular</li>
                    <li>Servicio urgente </li>
                    <li>Servicio consolidado a Sudamérica, Europa y Asia </li>
                  </ul>
                  <b><li>Transporte Marítimo</li></b>
                  <ul className='sub-points'>
                    <li>Full container load (FCL) </li>
                    <li>Less-than-container Load (LCL) </li>
                    <li>Coordinación de carga Refrigerada </li>
                    <li>Coordinación de carga y equipo sobredimensionado </li>
                    <li>Acondicionamiento, trincado y seguro de mercancía</li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='col-12 col-md-6 col-lg-4'>
        <div class="card">
          <img src={CamionLogo} class="card-img-top mx-auto d-block" alt="..." />
          <div class="card-body">
            <h5 class="card-title"><b>TRANSPORTE TERRESTRE</b></h5>
            <p class="card-text">Ofrecemos soluciones integrales en logística para distribución nacional, importación y exportación a través de nuestra infraestructura y alianzas estratégicas; en Estados Unidos, Canadá y Centroamérica.</p>
            <button class="btn btn-primary"
              data-toggle="modal" data-target="#modalTransporteTerrestre">Ver más</button>
          </div>
        </div>
        <div class="modal fade" id="modalTransporteTerrestre" tabindex="-1" aria-labelledby="modalTransporteTerrestre" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modalTransporteTerrestre"><b>TRANSPORTE TERRESTRE</b></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" align="left">
                <ul>
                  <b><li>Servicios locales, nacionales, internacional, puertos, aeropuertos y fronteras</li></b>
                  <ul className='sub-points'>
                    <li>Carga dedicada</li>
                    <li>Carga consolidada</li>
                  </ul>
                  <b><li>Tipos de unidades:</li></b>
                  <ul className='sub-points'>
                    <li>1 tonelada cerrada, estaca y plataforma </li>
                    <li>3.5 ton cerrada y plataforma</li>
                    <li>Rabón y Torton en plataforma o caja seca</li>
                    <li>Caja 48 pies y Caja 53 pies</li>
                    <li>Plataformas de 40, 48 y 53 pies</li>
                    <li>Full de plataformas y cajas secas.</li>
                    <li>Además, contamos con servicio de transfer en Nuevo Laredo, Laredo TX y servicio de almacén (CDMX, Nuevo Laredo, Veracruz, Manzanillo)</li>
                  </ul>
                  <b><li>Proyectos Especiales</li></b>
                  <ul className='sub-points'>
                    <li>Carga a granel</li>
                    <li>Maquinaria</li>
                    <li>Carga con exceso de dimensiones</li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default Servicios