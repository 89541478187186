import React from 'react'
import PinIcon from '../imgs/pin-icon.png'

function QuienesSomos() {
    return (
        <section className='row' id='quienes-somos'>
            <div className='col-12' align="center">
                <h2>
                    <b>
                        <span>¿QUIÉNES </span><span style={{ color: '#043071' }}>SOMOS?</span>
                    </b>
                </h2>
            </div>

            <div className='col-12 col-sm-4 text-center'>
                <img src={PinIcon}
                    alt="logo-header-img"
                    className='pin-icon' />
                <p className='lh-lg pt-4'>
                    Empresa de origen mexicano dedicada al comercio
                    exterior con más de 15 años de experiencia en el mercado.
                </p>
            </div>
            <div className='col-12 col-sm-4  text-center'>
                <img src={PinIcon}
                    alt="logo-header-img"
                    className='pin-icon' />
                <p className='lh-lg pt-4'>
                    Dedicados a dar un servicio integral.
                </p>
            </div>
            <div className='col-12 col-sm-4 text-center'>
                <img src={PinIcon}
                    alt="logo-header-img"
                    className='pin-icon' />
                <p className='lh-lg pt-4'>
                    Ofrecemos un servicio especializado de acuerdo a las necesidades de cada uno de nuestros clientes.
                </p>
            </div>
        </section>
    )
}

export default QuienesSomos