import './App.css';
import CoberturaAduanal from './components/CoberturaAduanal';
import Contacto from './components/Contacto';
import Footer from './components/Footer';
import Header from './components/Header';
import MisionVision from './components/MisionVision';
import Navbar from './components/Navbar';
import NuestroValor from './components/NuestroValor';
import QuienesSomos from './components/QuienesSomos';
import Servicios from './components/Servicios';
import WhatsAppButton from './components/WhatsAppButton';
import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";

function App() {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="container-fluid">
      <Navbar></Navbar>
      <div id='main'>
        <Header></Header>
        <QuienesSomos></QuienesSomos>
        <Servicios></Servicios>
        <CoberturaAduanal></CoberturaAduanal>
        <NuestroValor></NuestroValor>
        <MisionVision></MisionVision>
        <Contacto></Contacto>
        <Footer></Footer>
      </div>
      <WhatsAppButton></WhatsAppButton>
    </div>
  );
}

export default App;
